import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classes from './AppLayout.module.css';

const AppLayout: React.FC<{}> = ({ children }) => (
  <div className={classes.AppLayout}>
    <div className={classes.AppHeader}>
        Spindl
    </div>

    <Container className={classes.AppContainer}>
      <Row>
        <Col md={10}>
          {children}
        </Col>
      </Row>
    </Container>
  </div>
);

export default AppLayout;
