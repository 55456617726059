import React from 'react';
import cx from 'classnames';

import classes from './Text.module.css';

export enum Paragraph {
  P1 = 'P1',
  P2 = 'P2'
}
export enum Caption {
  C1 = 'C1',
  C2 = 'C2'
}
export enum Subtitle {
  S1 = 'S1',
  S2 = 'S2'
}
export enum Label {
  L1 = 'L1'
}

type TextVariant = Paragraph | Caption | Subtitle | Label;

type TextProps = {
  variant?: TextVariant;
  children: string;
  className?: string;
};

const variantClassMap: Record<TextVariant, string> = {
  [Paragraph.P1]: cx(classes.paragraph, classes.p1),
  [Paragraph.P2]: cx(classes.paragraph, classes.p2),
  [Caption.C1]: cx(classes.caption, classes.c1),
  [Caption.C2]: cx(classes.caption, classes.c2),
  [Subtitle.S1]: cx(classes.subtitle, classes.s1),
  [Subtitle.S2]: cx(classes.subtitle, classes.s2),
  [Label.L1]: cx(classes.label, classes.l1),
};

const Text: React.FC<TextProps> = ({
  variant = Paragraph.P1,
  className,
  children,
}) => {
  const variantClassName = variantClassMap[variant];

  return (
    <span className={cx(variantClassName, className)}>
      {children}
    </span>
  );
};


export default Text;
