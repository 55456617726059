import React from 'react';
import cx from 'classnames';
import Text from '../../atoms/Text';
import classes from './FilePreview.module.css';

export const formatDate = (d: number): string => (new Date(d)).toLocaleDateString('en-gb', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

export type FilePreviewProps = {
  selectedFile: File;
  onCancel: () => void;
  loading: boolean;
  success: boolean;
  error: string | undefined;
};

const FilePreview: React.FC<FilePreviewProps> = ({
  selectedFile,
  onCancel,
  loading,
  success,
  error,
}) => {
  const className = cx(
    classes.FilePreview,
    {
      [classes.loading]: loading,
      [classes.success]: success,
      [classes.error]: !!error,
    },
  );

  return (
    <div className={className}>
      <div className={classes.FilePreviewContent}>
        <h5>{selectedFile.name}</h5>

        <Text className={classes.lastModified}>
          {`Last modified on ${formatDate(selectedFile.lastModified)}`}
        </Text>

        <div>
          {loading && (
            <Text className={classes.loadingMessage}>
              Checking your file...
            </Text>
          )}

          {error && (
            <Text className={classes.errorMessage}>
              {error}
            </Text>
          )}

          {success && (
            <Text className={classes.successMessage}>
              File successfully submitted
            </Text>
          )}
        </div>
      </div>
      <div>
        {!success && !error && (
          <button
            onClick={onCancel}
            className={classes.FilePreviewCancelButton}
            data-testid='file-preview-cancel-button'
          ></button>
        )}
      </div>
    </div>
  );
};

export default FilePreview;
