import React from 'react';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import classes from './Button.module.css';

type ButtonProps = {
  active?: boolean;
  block?: boolean;
  children?: React.ReactNode | string;
  className?: string;
  disabled?: boolean;
  href?: string;
  outlined?: boolean;
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'reset' | 'submit';
  variant?: 'brand' | 'ash' | 'dark' | 'danger' | 'success' | 'warning' | 'info';
  loading?: boolean;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  active = false,
  block = false,
  children = null,
  className = '',
  disabled = false,
  href = '',
  outlined = false,
  size = 'lg',
  type = 'button',
  variant = 'brand',
  loading = false,
  onClick,
}: ButtonProps) => {
  const composedClassName = classNames(
    classes.button,
    classes[variant],
    classes[size],
    {
      [classes.outlined]: outlined,
      [classes.active]: active,
      [classes.disabled]: disabled,
      [classes.loading]: loading,
    },
    className,
  );

  return (
    <BootstrapButton
      active={active}
      block={block}
      className={composedClassName}
      disabled={disabled}
      href={href}
      type={type}
      onClick={onClick}
    >
      {
        loading
          ? <Spinner animation='border' />
          : children
      }
    </BootstrapButton>
  );
};

export default Button;
