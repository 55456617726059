import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Button from '../../atoms/Button';
import { HomeRouterParams } from './types';

const HomePage: React.FC<{}> = () => {
  const { path, params } = useRouteMatch<HomeRouterParams>();
  const { appId, serviceName, tabName } = params;
  const uploadUrl = `/${appId}/${serviceName}/upload`;

  return (
  <div>
    <h3>Database</h3>
    <Button href={uploadUrl}>Upload new</Button>
  </div>
  );
}
  
  
  

export default HomePage;
