import React from 'react';
import Button from "../../atoms/Button";
import { DATA_API_URL } from '../../../lib/config';

const DownloadPage: React.FC<{}> = () => {

  function handleClick() {
    window.location.replace(`${DATA_API_URL}/schema/dbml`)
  }

  return (
    <div>
      <h1>Download</h1>
      <Button onClick={handleClick} >Generate DBML</Button>
    </div>
  );
};

export default DownloadPage;
