declare global {
    interface Window {
      env: any;
    }
  }
  
  // eslint-disable-next-line import/prefer-default-export
  export const {
    REACT_APP_DATA_API_URL: DATA_API_URL = window.env?.REACT_APP_DATA_API_URL,
  
    REACT_APP_ADMIN_URL: ADMIN_URL = window.env?.REACT_APP_ADMIN_URL,
    REACT_APP_ADMIN_LOGIN_URL: ADMIN_LOGIN_URL = window.env?.REACT_APP_ADMIN_LOGIN_URL,
  } = process.env;
  
  export const IS_DEV = ADMIN_LOGIN_URL === '' || ADMIN_LOGIN_URL === undefined;
  