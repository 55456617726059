import yaml from 'yaml';

export const loadFileContent = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onabort = (): void => reject(new Error('File reading was aborted'));
  reader.onerror = (): void => reject(new Error('File reading has failed'));
  reader.onload = (): void => {
    const binaryStr = reader.result as string;
    resolve(binaryStr);
  };
  reader.readAsText(file);
});

export const parseYaml = (text: string): Promise<object> => {
  try {
    const data = yaml.parse(text);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(new Error(`Failed to parse yaml: ${error.message}`));
  }
};
