import React, { useReducer } from 'react';
import Button from '../../atoms/Button';
import classes from './UploadPage.module.css';
import FileSelector from '../../molecules/FileSelector';
import FilePreview from '../../molecules/FilePreview';
import { loadFileContent, parseYaml } from './helpers';
import { uploadSchema } from '../../../lib/api';

type UploadPageState = {
  selectedFile: File | undefined;
  parsedFileContent: object | undefined;
  uploadLoading: boolean;
  uploadSuccess: boolean;
  uploadError: string | undefined;
};

const initialUploadState: UploadPageState = {
  selectedFile: undefined,
  parsedFileContent: undefined,
  uploadLoading: false,
  uploadSuccess: false,
  uploadError: undefined,
};

const uploadReducer = (
  state: UploadPageState,
  changes: Partial<UploadPageState>,
): UploadPageState => ({
  ...state,
  ...changes,
});

const UploadPage: React.FC<{}> = () => {
  const [{
    selectedFile,
    parsedFileContent,
    uploadSuccess,
    uploadError,
    uploadLoading,
  }, dispatch] = useReducer(uploadReducer, initialUploadState);

  const resetState = (): void => dispatch(initialUploadState);

  const handleValidSelection = (data: object): void => {
    dispatch({
      parsedFileContent: data,
      uploadLoading: false,
    });
  };

  const handleError = (file: File, errorMessage: string): void => {
    dispatch({
      selectedFile: file,
      uploadLoading: false,
      uploadError: errorMessage,
    });
  };

  const handleSelectFile = (file: File): void => {
    dispatch({
      selectedFile: file,
      uploadLoading: true,
    });

    loadFileContent(file)
      .then(parseYaml)
      .then(handleValidSelection)
      .catch((error) => {
        handleError(file, error.message);
      });
  };

  const handleUploadSuccess = (): void => {
    dispatch({
      uploadLoading: false,
      uploadSuccess: true,
    });
  };

  const handleUploadError = (error: Error): void => {
    dispatch({
      uploadLoading: false,
      uploadError: error.message,
    });
  };

  const uploadFile = (): void => {
    if (parsedFileContent === undefined) return;

    if (parsedFileContent === null) {
      dispatch({
        uploadError: 'Empty File'
      });
      return;
    }

    dispatch({ uploadLoading: true });

    uploadSchema(parsedFileContent)
      .then(handleUploadSuccess)
      .catch(handleUploadError);
  };

  const noFileSelected = selectedFile === undefined;

  return (
    <div className={classes.UploadPage}>
      <h3>Upload Database File</h3>

      <div className={classes.UploadPageForm}>
        {noFileSelected && !uploadError && (
          <FileSelector
            onFileSelected={handleSelectFile}
            onError={handleError}
          />
        )}

        {!!selectedFile && (
          <FilePreview
            selectedFile={selectedFile}
            onCancel={resetState}
            success={uploadSuccess}
            error={uploadError}
            loading={uploadLoading}
          />
        )}
      </div>

      <UploadButton
        onSubmit={uploadFile}
        onCancel={resetState}
        selectedFile={selectedFile}
        uploadSuccess={uploadSuccess}
        uploadError={uploadError}
        uploadLoading={uploadLoading}
      />
    </div>
  );
};

const UploadButton: React.FC<{
  onSubmit: () => void;
  onCancel: () => void;
  selectedFile: File | undefined;
  uploadLoading: boolean;
  uploadSuccess: boolean;
  uploadError: string | undefined;
}> = ({
  onSubmit,
  onCancel,
  selectedFile,
  uploadSuccess,
  uploadError,
  uploadLoading,
}) => {
  if (uploadSuccess) return <Button onClick={onCancel}>Close</Button>;

  if (uploadError) {
    return <Button onClick={onCancel}>Upload different file</Button>;
  }

  const noFileSelected = selectedFile === undefined;

  return (
    <Button
      onClick={onSubmit}
      loading={uploadLoading}
      disabled={noFileSelected}
    >
      Submit
    </Button>
  );
};

export default UploadPage;
