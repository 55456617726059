import React from 'react';
import cx from 'classnames';
import { useDropzone, FileRejection } from 'react-dropzone';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import classes from './FileSelector.module.css';
import UploadImage from '../../../resources/img/upload.svg';

const FileSelector: React.FC<{
  onFileSelected: (file: File) => void;
  onError: (selectedFile: File, errorMessage: string) => void;
}> = ({ onFileSelected, onError }) => {
  const handleDrop = (files: File[]): void => {
    if (files.length < 1) return;
    const file = files[0];
    onFileSelected(file);
  };

  const handleDropRejected = (rejections: FileRejection[]): void => {
    const { file, errors } = rejections[0];

    const errorMessage = errors
      .map(({ message }) => message)
      .join();

    onError(file, `Invalid File: ${errorMessage}.`);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    noClick: true,
    accept: '.yml, .yaml',
    multiple: false,
  });

  const dropZoneClassName = cx(
    classes.FileSelectorDropZone,
    {
      [classes.dragActive]: isDragActive,
    },
  );

  return (
    <div className={classes.FileSelector}>
      <div {...getRootProps({
        className: dropZoneClassName,
      })}>
        <div className={classes.FileSelectorContainer}>
          <img
            src={UploadImage}
            alt=''
          />

          <Button onClick={openFileDialog}>
            Select File
          </Button>

          <Text>or drag and drop file here to upload</Text>
        </div>
        <input
          data-testid='file-selector-input'
          {...getInputProps()}
        />
      </div>
    </div>
  );
};

export default FileSelector;
