import { getServerUrl } from './utils';

// eslint-disable-next-line import/prefer-default-export
export async function uploadSchema(schema: object): Promise<void> {
  const res = await fetch(`${getServerUrl()}/schema`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(schema),
  });

  if (!res.ok) {
    const errorMessage = await res.text();
    throw new Error(errorMessage);
  }
}
